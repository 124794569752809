var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem',
      }),attrs:{"content-position":"left"}},[_vm._v("基本信息")]),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field-select',{attrs:{"options":_vm.politics,"fieldProps":{
        label: '政治面貌',
        required: true,
        placeholder: '请选择政治面貌',
      }},model:{value:(_vm.form.politicCountenance),callback:function ($$v) {_vm.$set(_vm.form, "politicCountenance", $$v)},expression:"form.politicCountenance"}}),_c('van-field',{attrs:{"name":"cmpy","label":"工作单位","placeholder":"请输入工作单位","required":""},model:{value:(_vm.form.cmpy),callback:function ($$v) {_vm.$set(_vm.form, "cmpy", $$v)},expression:"form.cmpy"}}),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem',
        }),attrs:{"content-position":"left"}},[_vm._v("最高学历信息")]),_c('van-field-select',{attrs:{"options":_vm.educations,"fieldProps":{
          label: '学历',
          placeholder: '请选择学历',
          required: true,
        }},model:{value:(_vm.form.education),callback:function ($$v) {_vm.$set(_vm.form, "education", $$v)},expression:"form.education"}}),_c('van-field-select',{attrs:{"options":_vm.educationTypes,"fieldProps":{
          label: '学历形式',
          placeholder: '请选择学历形式',
          required: true,
        }},model:{value:(_vm.form.educationType),callback:function ($$v) {_vm.$set(_vm.form, "educationType", $$v)},expression:"form.educationType"}}),_c('van-field',{attrs:{"name":"college","label":"毕业院校","placeholder":"请输入毕业院校","maxlength":"100","required":""},model:{value:(_vm.form.college),callback:function ($$v) {_vm.$set(_vm.form, "college", $$v)},expression:"form.college"}}),_c('van-field',{attrs:{"name":"major","label":"所学专业","placeholder":"请输入所学专业","maxlength":"100","required":""},model:{value:(_vm.form.major),callback:function ($$v) {_vm.$set(_vm.form, "major", $$v)},expression:"form.major"}}),_c('van-field-day',{attrs:{"name":"graduateDate","placeholder":"毕业时间","pickerProps":{
          type: 'date',
        },"fieldProps":{
          label: '毕业时间',
          placeholder: '请选择',
          required: true,
        }},model:{value:(_vm.form.graduateDate),callback:function ($$v) {_vm.$set(_vm.form, "graduateDate", $$v)},expression:"form.graduateDate"}}),_c('van-field-select',{attrs:{"options":_vm.overSeaTypes,"fieldProps":{
          label: '海外留学经历',
          placeholder: '请选择海外留学经历',
          required: true,
        }},model:{value:(_vm.form.overSea),callback:function ($$v) {_vm.$set(_vm.form, "overSea", $$v)},expression:"form.overSea"}})],1),_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem',
      }),attrs:{"content-position":"left"}},[_vm._v("申请人社保卡信息（金融账户）")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v(" * 1、开户银行具体到支行和行号，若不清楚请拨打银行客服热线进行咨询，以下内容请务必准确无误，否则将影响补贴到账进度或产生退件 ")])]),_c('van-field',{attrs:{"name":"bankId","label":"社会保障卡开户银行","placeholder":"请输入社会保障卡开户银行","required":"","maxlength":"100"},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"社会保障卡金融账号","placeholder":"请输入社会保障卡金融账号","required":"","maxlength":"20"},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"bankBranch","label":"社会保障卡支行名称","placeholder":"请输入社会保障卡支行名称","required":"","maxlength":"20"},model:{value:(_vm.form.bankBranch),callback:function ($$v) {_vm.$set(_vm.form, "bankBranch", $$v)},expression:"form.bankBranch"}}),_c('van-field',{attrs:{"name":"bankCode","label":"行号","placeholder":"请输入行号","required":"","maxlength":"20"},model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }